<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Reparto"
    :route-father="routeFather"
  >
    <template v-slot:header-actions
      v-if="!!currentUser && !!currentUser.system && !isLicenseBlocked"
    >
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div 
        :style="{
          'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
          'overflow-y': 'hidden'
        }" 
        ref="dataTableWrapper"
      >
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento reparti ..."
          :headers="headers"
          :loading="loading"
          :items="filteredServiceTypesCategories"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
          :show-actions="!!currentUser && !!currentUser.system && !isLicenseBlocked"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import serviceTypesCategoriesService from '@/services/serviceTypesCategories/serviceTypesCategories.service.js'

export default {
  name: "ServiceTypesCategoriesRegistryList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter
  },
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      serviceTypesCategories: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined, },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      currentUser: {},
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchServiceTypesCategories()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchServiceTypesCategories() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      serviceTypesCategoriesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.serviceTypesCategories = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      serviceTypesCategoriesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(serviceTypesCategory) {
      this.$router.push({
        name: 'ServiceTypesCategoriesRegistryEditForm',
        params: {
          id: serviceTypesCategory.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesCategoriesRegistryList'}).href,
          pathName: "ServiceTypesCategoriesRegistryList"
        }
      })
    },
    handleEditDoubleClick(row, {item: serviceTypesCategory}) {
      this.$router.push({
        name: 'ServiceTypesCategoriesRegistryEditForm',
        params: {
          id: serviceTypesCategory.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesCategoriesRegistryList'}).href,
          pathName: "ServiceTypesCategoriesRegistryList"
        }
      })
    },
    handleDelete(serviceTypesCategory) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + serviceTypesCategory.name + ' ?')
      
      if(confirmed) {
        if (!!serviceTypesCategory.serviceTypes && !!serviceTypesCategory.serviceTypes.length > 0)
          this.$delegates.snackbar('Delle famiglie sono associate a questo reparto, impossibile elimarlo')
        else {
          this.loadingItems = true
          serviceTypesCategoriesService.archive(serviceTypesCategory).then(() => {
            this.fetchServiceTypesCategories()
            this.loadingItems = false
          })
        }
      }
    },
    goToNew() {
      this.$router.push({
        name: 'ServiceTypesCategoriesRegistryNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesCategoriesRegistryList'}).href
        }
      })
    },
    applyFilters() {
      this.fetchServiceTypesCategories();
    },
  },
  computed: {
    filteredServiceTypesCategories() {
      return this.serviceTypesCategories
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchServiceTypesCategories(this.currentFilters)
    },
    rowPerPage() {
      this.fetchServiceTypesCategories(this.currentFilters)
    },
  },  
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>